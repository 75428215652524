import React from "react"
import Heading from "../../components/Heading"
import Text from "../../components/Text"
import Container from "../../components/UI/ContainerTwo"

const Consejos = () => {
  return (
    <Container>
      <Heading
        as="h5"
        content="Te proporcionamos 10 consejos para mantener en buen estado tus llantas:"
      />
      <Text
        content={
          <ol>
            <li>
              Controlar las presiones de inflado antes de emprender el viaje.
            </li>
            <li>
              Inspeccionar los indicadores de desgaste y el estado general de
              las llantas.
            </li>
            <li>Respetar los índices de carga y velocidad.</li>
            <li>
              Adecuar la conducción a las características y circunstancias del
              terreno.
            </li>
            <li>Evitar frenazos y aceleraciones bruscas.</li>
            <li>Verificar la llanta de refacción.</li>
            <li>Revisar los órganos de la dirección, suspensión y frenado.</li>
            <li>
              Acudir siempre a un especialista que realice las labores básicas
              de mantenimiento del neumático.
            </li>
            <li>
              Elegir una llanta adecuada para las condiciones del terreno por
              dónde vamos a rodar, no es lo mismo lluvia que desierto.
            </li>
            <li>
              No sobrepasar la vida útil de un neumático, que viene determinada,
              no por el desgaste de su banda de rodamiento, sino por su estado
              de conservación general y el número de kilómetros recorridos.
            </li>
          </ol>
        }
      />
    </Container>
  )
}

export default Consejos
